import { produce } from 'immer';
import initialState from './initialState';
import * as types from 'constants/actionTypes';

const calculationsReducer = (state = initialState.calculations, action) =>
    produce(state, draft => {
        switch (action.type) {
            case types.Calculation.INPUTS_LOAD:
                draft[action.calculationType] = {
                    ...draft[action.calculationType],
                    loading: true,
                    error: false,
                    response: {},
                    selectedExchanger: null,
                    calculation: {},
                    selectedArticle: {
                      loading: false,
                      error: false,
                      article: null,
                    },
                    selectedArticlePorts: {
                      loading: false,
                      error: false,
                      portInfo: null,
                      portConfiguration: null
                    },
                };
                return draft;
            case types.Calculation.INPUTS_LOAD_SUCCESS: {
                const inputParams = {...action.value };
                draft = {
                    [action.calculationType]: {
                        ...draft[action.calculationType],
                        loading: false,
                        error: false,
                        inputParams: inputParams,
                        calculation: {
                            type: action.calculationType,
                            method: action.value.userCalculationInputs.calculationMethod,
                            id: action.calculationId
                        },
                        selectedExchanger: null,
                    }
                };

                return draft;
            }
            case types.Calculation.UPDATE_PARAMETERS: {
                const values = action.values || [];
                values.forEach(o => {
                    draft[action.calculationType].inputParams.userCalculationInputs[o.fieldName] = o.value;
                });
                return draft;
            }
            case types.Calculation.SET_INPUTS_VALIDITY:
                draft[action.calculationType].inputParams.isValid= action.value;
                return draft;
            case types.Calculation.INPUTS_LOAD_ERROR:
                draft.loading = true;
                draft.error = false;
                draft[action.calculationType] = {
                    loading: false,
                    error: action.error,
                    inputs: {}
                };
                return draft;

            // case CALCULATION_INPUTS_HEATEXCHANGERS_LOAD:
            //     break;
            case types.Calculation.INPUTS_HEATEXCHANGERS_SUCCESS:
                draft[action.calculationType].inputParams.availableHeatExchangers = action.exchangers;
                break;
            case types.Calculation.CALCULATE_BEGIN: {
                draft =  {
                    [action.calculationType]: {
                        ...draft[action.calculationType],
                        isCalculating: true,
                        selectedExchanger:null
                    }
                };
                return draft;
            }
            case types.Calculation.CALCULATE_SUCCESS: {
                draft = {
                    [action.calculationType]: {
                        ...draft[action.calculationType],
                        selectedExchanger: state[action.calculationType].selectedExchanger,
                        response: action.value,
                        isCalculating: false
                    }
                };

                return draft;
            }
            case types.SELECT_HEAT_EXCHANGER: {
                draft[action.calculationType].selectedExchanger = action.selectedExchanger;
                return draft;
            }
          case types.SELECT_ITEM: {
            draft[action.calculationType].selectedExchanger = action.selectedHeatExchanger;
            draft[action.calculationType].selectedItem = action.selectedItem;
            draft[action.calculationType].selectedArticle.article = null;
            draft[action.calculationType].selectedArticlePorts.portInfo = null;
            draft[action.calculationType].selectedArticlePorts.portConfiguration = null;
            break;
          }
          case types.ARTICLE_LOAD: {
            const selectedArticle = draft[action.calculationType].selectedArticle;
            selectedArticle.loading = true;
            selectedArticle.error = false;
            selectedArticle.article = null;
            break;
          }
          case types.ARTICLE_LOAD_SUCCESS: {
            const selectedArticle = draft[action.calculationType].selectedArticle;
            selectedArticle.loading = false;
            selectedArticle.error = false;
            selectedArticle.article = action.article;
            break;
          }
          case types.ARTICLE_LOAD_ERROR: {
            const selectedArticle = draft[action.calculationType].selectedArticle;
            selectedArticle.loading = false;
            selectedArticle.error = action.error;
            selectedArticle.article = null;
            break;
          }
          case types.FILTERS_SELECTION_CHANGED1: {
                draft[action.calculationType].inputParams.filterSettings = action.filterSettings;
                break;
            }
          case types.FILTERS_CUSTOM_CHANGED:
            draft[action.calculationType].inputParams.filterSettings.customFilterSelections = action.selections;
            break;
          case types.Calculation.OUTPUT_COLUMNS_CHANGE:
          {
            draft[action.calculationType].response.calculationOutputColumns = action.userDefaultCalculationOutputColumns;
            break;
          }
          case types.ARTICLES_PORT_CONFIGUATION_LOAD: {
            const selectedArticlePorts = draft[action.calculationType].selectedArticlePorts;
            selectedArticlePorts.loading = true;
            selectedArticlePorts.error = false;
            selectedArticlePorts.portInfo = null;
            selectedArticlePorts.portConfiguration = null;
            break;
          }
          case types.ARTICLES_PORT_CONFIGUATION_LOAD_SUCCESS: {
            const selectedArticlePorts = draft[action.calculationType].selectedArticlePorts;
            selectedArticlePorts.loading = false;
            selectedArticlePorts.error = false;
            selectedArticlePorts.portInfo = action.portInfo;
            selectedArticlePorts.portConfiguration = action.portConfiguration;
            break;
          }
          case types.ARTICLES_PORT_CONFIGUATION_LOAD_ERROR: {
            const selectedArticlePorts = draft[action.calculationType].selectedArticlePorts;
            selectedArticlePorts.loading = false;
            selectedArticlePorts.error = action.error;
            selectedArticlePorts.portInfo = null;
            selectedArticlePorts.portConfiguration = null;
            break;
          }
        }
    });

export default calculationsReducer;
