import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import { ClipLoader, RingLoader, PulseLoader, PropagateLoader, CircleLoader} from 'react-spinners';
import { useTheme } from '@material-ui/core/styles';
import get from 'lodash/get';

const LoadingIndicator = ({type, properties, noWrapper}) => {
  const theme = useTheme();
  const defaultColor = get(theme, 'palette.primary.main', "#B91D23");

  if(type === 'RingLoader'){
    return (<>
      {noWrapper && <RingLoader color={defaultColor} {...properties}/>}
      {!noWrapper && (
      <Wrapper>
        <RingLoader color={defaultColor} {...properties}/>
      </Wrapper>
      )}
      </>
    );
  }
  if(type === 'PulseLoader'){
    return (<>
      {noWrapper && <PulseLoader color={defaultColor} {...properties}/>}
      {!noWrapper && (
      <Wrapper>
        <PulseLoader color={defaultColor} {...properties}/>
      </Wrapper>
      )}
      </>
    );
  }
  if(type === 'PropagateLoader'){
    return (<>
      {noWrapper && <PropagateLoader color={defaultColor} {...properties}/>}
      {!noWrapper && (
      <Wrapper>
        <PropagateLoader color={defaultColor} {...properties}/>
      </Wrapper>
      )}
      </>
    );
  }
  if(type === 'CircleLoader'){
    return (<>
      {noWrapper && <CircleLoader color={defaultColor} {...properties}/>}
      {!noWrapper && (
      <Wrapper>
        <CircleLoader color={defaultColor} {...properties}/>
      </Wrapper>
      )}
      </>
    );
  }
  return (<>
    {noWrapper && <ClipLoader color={defaultColor} {...properties}/>}
    {!noWrapper && (
    <Wrapper>
      <ClipLoader color={defaultColor} />
    </Wrapper>
     )}
     </>
  );
};

LoadingIndicator.propTypes = {
  type: PropTypes.string,
  properties: PropTypes.object,
  noWrapper: PropTypes.bool
};

export default LoadingIndicator;
