import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createReducer from '../reducers';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';
import { devToolsEnhancer } from 'redux-devtools-extension';
import * as allActions from '../actions/calculationActions';

const connectRouterHistory = connectRouter(history);
let reduxSagaMonitorOptions = {};



const developmentToolEnhancer = devToolsEnhancer({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  actionCreators: allActions,
  trace: true,
  traceLimit: 25
});


function configureStore(initialState, history) {
  let composeEnhancers = compose;

  // if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
  //   /* eslint-disable no-underscore-dangle */
  //   if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  //     composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  //
  //     ////Bellow is code for REDUX-SAGA extension
  //     // reduxSagaMonitorOptions.sagaMonitor = window["__SAGA_MONITOR_EXTENSION__"];
  // }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const middlewares = [
    reduxImmutableStateInvariant(),
    thunk,
    routerMiddleware(history),
    sagaMiddleware
  ];

  const enhancers = [applyMiddleware(...middlewares), developmentToolEnhancer];

  const store = createStore(
    createReducer(), // root reducer with router state
    initialState,
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
      module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(connectRouterHistory(nextRootReducer));
      // store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}

// function configureStoreProd(initialState, history) {
//   const reactRouterMiddleware = routerMiddleware(history);
//   const middlewares = [
//     reduxImmutableStateInvariant(),
//     thunk,
//     reactRouterMiddleware,
//     routerMiddleware(history),
//     sagaMiddleware
//   ];

//   const store = createStore(
//     createReducer(), // root reducer with router state
//     initialState,
//     compose(
//       applyMiddleware(...middlewares)
//     )
//   );

//   // Extensions
//   store.runSaga = sagaMiddleware.run;
//   store.injectedReducers = {}; // Reducer registry
//   store.injectedSagas = {}; // Saga registry

//   return store;
// }

// function configureStoreDev(initialState, history) {
//   const reactRouterMiddleware = routerMiddleware(history);
//   const middlewares = [
//     // Add other middleware on this line...

//     // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
//     reduxImmutableStateInvariant(),

//     // thunk middleware can also accept an extra argument to be passed to each thunk action
//     // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
//     thunk,
//     reactRouterMiddleware,
//     sagaMiddleware
//   ];

//   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
//   const store = createStore(
//     createReducer(), // root reducer with router state,
//     initialState,
//     composeEnhancers(
//       applyMiddleware(...middlewares)
//     )
//   );

//   // Extensions
//   store.runSaga = sagaMiddleware.run;
//   store.injectedReducers = {}; // Reducer registry
//   store.injectedSagas = {}; // Saga registry

//     // Make reducers hot reloadable, see http://mxs.is/googmo
//   /* istanbul ignore next */
//   if (module.hot) {
//     module.hot.accept('../reducers', () => {
//       store.replaceReducer(createReducer(store.injectedReducers));
//     });
//   }

//   return store;
// }

// const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
