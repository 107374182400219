import initialState from './initialState';
import { LOAD_SKETCHES } from '../constants/actionTypes';

export default function loadItemsReducer(state = initialState.loadItems, action) {
    let newState;

    switch(action.type){
        case LOAD_SKETCHES: {
            let {...ns} = state;
            newState = ns;
            newState.sketches = action.value;
            return newState;
        }
        default:
            return state;
    }
}
