import * as types from 'constants/actionTypes';
import { produce } from 'immer';

export const initialState = {
    error: null,
    info: null,
    auth: {
        userId: null,
        expiredAt: 0
    }
};

const applicationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type){
        case types.Application.ERROR_WEBAPI:
        case types.Application.ERROR_USERMADE:
            draft.error = {
                title: action.value.title,
                msg: action.value.message,
                error: action.value.error,
            };
            break;
        case types.Application.CLEAR_LOGOUT_FLAG:
            draft.logout = false;
            break;
        case types.Application.ERROR_RESET:
            draft.error = null;
            break;
        case types.Application.INFO_MSG:
            draft.info = {
                title: action.value.title,
                msg: action.value.message
            };
            break;
        case types.Application.INFO_RESET:
            draft.info = null;
            break;
    }
  });

export default applicationReducer;
