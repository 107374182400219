/* eslint-disable import/default */
import { AppContainer } from 'react-hot-loader';
import React from 'react';
import {render} from 'react-dom';

import configureStore from './store/configureStore';
require('./favicon.ico'); // Tell webpack to load favicon.ico
import 'styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import 'typeface-roboto'; //Roboto fonts for material design
import Root from 'components/Root';
import '../node_modules/toastr/build/toastr.min.css';
import history from './utils/history';


if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  //const ReactRedux = require('react-redux');
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    trackExtraHooks: [
      // [ReactRedux, 'useSelector']
    ]
  });
}

const store = configureStore();

const MOUNT_NODE = document.getElementById('app');
render(
    <AppContainer>
      <Root store={store} history={history} />
    </AppContainer>
    ,
    MOUNT_NODE
);

if (module.hot) {

  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default; // Get the updated code
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      MOUNT_NODE
    );
  });
}
