import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from '../utils/history';

import application from './applicationReducer';
import loadItems from './loadItemsReducer';
import calculations from './calculationsReducer';
import userProfile from './userProfileReducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    // global: globalReducer,
    router: connectRouter(history),
    application,
    loadItems,
    calculations,
    userProfile,
    ...injectedReducers,
  });

  return rootReducer;
}
