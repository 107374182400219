import {
  Temperature,
  TemperatureDifference,
  UnitGroupsNames
} from "../utils/unitGroups.ts";

export default {
  headerType: 'FULL',
  application: {
    logout: false,
    error: null
  },
  unitConversion: {},
  loadItems: {
    fluids: {
      singlePhase: []
    },
    countries: []
  },
  calculations: {

  },
  filtersData: {
    factories: [],
    materials: [],
    pressures: [],
    warehouses: [],
    defaultSelection: {}
  },
  fluidProperties: {
    fluids: [],
    selectedInputs: {
      selectedFluid: {
        fluidId:53,
        inputValue: null,
        isGas: false
      },
      initialTemperature: {
        defaultMetricUnit: Temperature.C,
          defaultUsUnit: Temperature.F,
          quantity: {
          amount: 80,
            unit: Temperature.C
        },
        unitGroup: UnitGroupsNames.Temperatures
      },
      finalTemperature:{
        defaultMetricUnit: Temperature.C,
        defaultUsUnit: Temperature.K,
        quantity: {
          amount: 95,
          unit: Temperature.C
        },
        unitGroup: UnitGroupsNames.Temperatures
      },
      //{ siValue: "95", unit: "°C", value: "80" },
      incrementBy: {
        defaultMetricUnit: TemperatureDifference.K,
        defaultUsUnit: TemperatureDifference.F,
        quantity: {
          amount: 5,
          unit: TemperatureDifference.K
        },
        unitGroup: UnitGroupsNames.TemperatureDifferances
      },
      units: "Metric"
    },
    calculationOutput: {
      CalculatedFluidProperties: []
    }
  },
  userProfile: {
      email: '',
      userId: '',
      filterSettings: {
        defaultCalculationFilterSettings: {},
        calculationTypeFilterSettings: {}
      },
      settings: {
        workingLanguage: "EN",
        printouts: {
          arePrintoutsShortened: false,
          languagePrimary: "EN",
          languageSecondary: null
        },
        units: "Metric"
      }
  },
  connections: {
    isOpen: false,
    loaders: {
      calculation: false,
      filter: false
    },
    filters: {
        material: [],
        pressure: [],
        portConfigurations: []
    },
    connections: {},
    calculationType: "",
    initialPortConfig: "",
    selectedArticle: null,
    connectionsData: []
  }
};
